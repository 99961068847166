/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, Fragment, lazy, Suspense } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import AppHealth from "./components/AppHealth";
import LoadingScreen from "./components/LoadingScreen";
import NotFound from "./components/NotFound";
import Login from "./containers/auth/Login";
import { SigninCallback } from "./containers/auth/SigninCallback";
import { SignoutCallback } from "./containers/auth/SignoutCallback";
import { SilentRenewCallback } from "./containers/auth/SilentRenewCallback";
import Dashboard from "./containers/dashboard/Dashboard";
import { BasicLayout } from "./containers/layouts/BasicLayout";
import Layout from "./containers/layouts/Layout";
import { Page } from "./containers/layouts/Page";
import AuthGuard from "./contexts/AuthGuard";
import GuestGuard from "./contexts/GuestGuard";
import { RouteTable } from "./features/utils/route-paths";

type RouteDef = {
  path?: string;
  title?: string;
  children?: RouteDef[];
  guard?: any;
  layout?: any;
  component?: any;
  caseSensitive?: boolean;
  index?: boolean;
  loading?: string;
};

const routeDefs: RouteDef[] = [
  {
    path: "/login",
    title: "Sign In",
    component: Login,
    guard: GuestGuard,
  },
  {
    path: "/signin-oidc",
    title: "Sign In",
    component: SigninCallback,
  },
  {
    path: "/signout-callback",
    title: "Sign Out",
    component: SignoutCallback,
  },
  {
    path: "/silentrenew",
    component: SilentRenewCallback,
  },
  {
    path: `/health/${process.env.REACT_APP_HEALTHKEY}`,
    component: AppHealth,
  },
  {
    path: "/",
    component: Layout,
    guard: AuthGuard,
    children: [
      {
        index: true,
        component: Dashboard,
        title: "Dashboard",
        loading: "loading dashboard...",
      },
      {
        path: RouteTable.questions,
        title: "Questions",
        component: lazy(() => import("./containers/policies/QuestionIndex")),
        loading: "loading questions...",
      },
      {
        path: RouteTable.questions + "/:questionCode",
        title: "Question Edit",
        component: lazy(() => import("./containers/policies/QuestionEdit")),
        loading: "loading questions...",
      },
      {
        path: RouteTable.absenceReasons,
        title: "Absence Reasons",
        component: lazy(
          () => import("./containers/policies/AbsenceReasonIndex")
        ),
        loading: "loading absence reasons...",
      },
      {
        path: RouteTable.absenceReasons + "/:absenceReasonCode",
        title: "Absence Reason Edit",
        component: lazy(
          () => import("./containers/policies/AbsenceReasonEdit")
        ),
        loading: "loading absence reasons...",
      },
      {
        path: RouteTable.policies,
        title: "Policies",
        component: lazy(() => import("./containers/policies/PolicyIndex")),
        loading: "loading policies...",
      },
      {
        path: RouteTable.policies + "/:policyCode",
        title: "Policy Edit",
        component: lazy(() => import("./containers/policies/PolicyEdit")),
        loading: "loading policies...",
      },
      {
        path: "/tests",
        title: "Tests",
        component: lazy(() => import("./containers/tests/TestIndex")),
        loading: "loading tests...",
      },
      {
        path: "/settings",
        title: "Settings",
        component: lazy(() => import("./containers/settings/SettingsIndex")),
        loading: "loading settings...",
      },
    ],
  },
  { path: "*", title: "Not Found", component: NotFound, layout: BasicLayout },
];

const buildRoutes = (defs: RouteDef[]): RouteObject[] => {
  return defs.map((d) => {
    const Guard = d.guard ?? Fragment;
    const Component = d.component ?? Fragment;
    const Layout = d.layout ?? Fragment;
    const route = {
      path: d.path,
      element: (
        <Suspense fallback={<LoadingScreen title={d.loading} />}>
          <Guard>
            <Layout>
              <Page title={d.title}>
                <Component />
              </Page>
            </Layout>
          </Guard>
        </Suspense>
      ),

      caseSensitive: d.caseSensitive,
    };
    return d.index
      ? {
          ...route,
          index: true,
        }
      : {
          ...route,
          index: false,
          children: d.children && buildRoutes(d.children),
        };
  });
};

export const AppRoutes: FC = () => {
  const routes = buildRoutes(routeDefs);
  const element = useRoutes(routes);

  return <>{element}</>;
};

export default AppRoutes;
